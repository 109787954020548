import http from '../utils/request'


// 获取轮播图详情，
export const getproductsInfo = (params) => {
    return http.get("/product/getproductsInfo",params)
}


export const uploadFile = (data) => {
    const formData =new FormData();
    formData.append("file",data)
    return http.post("/product/uploadFile",formData)
}


export const deletFile = (data) => {
    const formData =new FormData();
    formData.append("filepath",data)
    return http.post("/product/deletFile",formData)
}

//轮播图保存
export const saveproductInfo = (data) => {
    
    return http.post("/product/save", data)
}


// 删除
export const batchDelete = (params) => {
    return http.get("/product/delete", params)
}

//获取所有的产品领域

export const getProductArea = (params) => {
    return http.get("/product/getProductArea", params)
}

//更新产品的推荐状态
export const updateProductRecommendStatus = (params) => {
    return http.get("/product/updateProductRecommendStatus", params)
}