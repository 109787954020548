import http from '../utils/request'

// 获取轮播图详情，
export const getCarouselImages = () => {
    return http.get("/banner/getCarouselImages")
}


export const uploadFile = (data) => {
    const formData =new FormData();
    formData.append("file",data)
    return http.post("/banner/uploadFile",formData)
}


export const deletFile = (data) => {
    const formData =new FormData();
    formData.append("filepath",data)
    return http.post("/banner/deletFile",formData)
}

//轮播图保存
export const saveBannerInfo = (data) => {
    
    return http.post("/banner/save", data)
}


// 删除
export const batchDelete = (params) => {
    return http.get("/banner/delete", params)
}