import axios from 'axios'
import Cookies from 'js-cookie'
import { refreshToken } from "@/api";

// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'; 
// 是否正在刷新的标记
let isRefreshing = false

// 重试队列，每一项将是一个待执行的函数形式
let requests = []


const http = axios.create({

    baseURL: '/api',// 请求url,可根据实际需要修改后端的url
    timeout: 10000, //请求超时时间


})



//请求拦截器
http.interceptors.request.use(function (config) {

    const headers = config.headers

    if (!headers.token) {
        headers.token = Cookies.get("token")
    }


    //在发送请求前做什么
    return config
}, function (error) {
    //对请求错误做什么处理
    return Promise.reject(error)
})


//响应拦截器

http.interceptors.response.use(function (response) {
    //在发送请求后做什么
    // console.log(response, 'response')

    if (response && response.data.code === 401) {
        // token续签方式2: refreshToken(用户无感知)
        // 将过期的token值清空
        Cookies.remove("token")
        //请求刷新token接口
        const params = { params: { userId: Cookies.get("userid") } };
        return refreshToken(params).then(({ data }) => {
            console.log(data, 'refreshToken')
            if (data.code == 200) {
                // localStorage.setItem("token",data.token)
                Cookies.set("token", data.msg)
                // 把新的token赋予到下一次axios请求的请求头中
                response.config.headers.token = data.msg
                // return到await的地方，将未完成的请求再次发起，
                return http(response.config)
            }

        });
        //保存新的token值

        // 再调用一次未完成的请求啊(用户无感知)
        // error.config 就是上一次axios请求的配置对象
        // console.dir(error.config)

    }

    return response
}, function (error) {
    //对请求错误做什么处理
    if (error.response && error.response.status === 401) {
        // token续签方式2: refreshToken(用户无感知)
        // 将过期的token值清空
        Cookies.remove("token")
        //请求刷新token接口
        const params = { params: { userId: Cookies.get("userid") } };
        refreshToken(params).then(({ data }) => {

            if (data.code == 200) {
                // localStorage.setItem("token",data.token)
                Cookies.set("token", data.token)
            }

        });
        //保存新的token值

        // 再调用一次未完成的请求啊(用户无感知)
        // error.config 就是上一次axios请求的配置对象
        // console.dir(error.config)
        // 把新的token赋予到下一次axios请求的请求头中
        error.config.headers.token = Cookies.get("token")
        // return到await的地方，将未完成的请求再次发起，
        return axios(error.config)
    } 
    return Promise.reject(error)
})

export default http