<!--  -->
<template>
  <div class="tags">
    <el-tag
      v-for="(item ,index) in tags"
      :key="item.path"
      :closable="item.name !== 'home'"
      @click="changeMenu(item)"
      @close="handleClose(item,index)"
      size="small"
      :effect="($route.fullPath === item.path)  ? 'dark' : 'plain'"
    >
      {{ item.label }}
    </el-tag>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "commontag",
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {};
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState({
      tags: (state) => state.tab.tabsList,
    }),
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //将stroe的tab.js 的closeTag()方法注册到本对象中，便于下面调用
    ...mapMutations(["closeTag"]),
    //点击tag跳转对应的页面
    changeMenu(item) {
      console.log(item,"tager")
      this.$router.push(item.path);
    },
    //点击tag删除事件
    handleClose(item, index) {
        console.log(index ,"index")
      this.closeTag(item);
      const lenth = this.tags.length  ;
      //删除后的处理逻辑
      if(item.path !== this.$route.fullPath){
        return
      }
      //表示删除的最后一项
      if(index ===length){
        this.$router.push({name: this.tags[index].name})
      }else{
        //如果删除的是中间
        this.$router.push(this.tags[index-1].path)
      }
    
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
.tags{
    padding: 20px;
    .el-tag{
        margin-right: 15px;
        cursor: pointer;
    }
}
//@import url(); 引入公共css类
</style>