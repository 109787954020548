import http from '../utils/request'




//菜单保存
export const saveMenu = (data) => {

    return http.post("/sys-menu/save", data)
}


// 删除
export const batchDelete = (params) => {
    return http.get("/sys-menu/delete", params)
}


// 查找导航菜单树
export const findNavTree = (params) => {
    return http.get("/sys-menu/findNavTree", params)
}


// 刷新导航和路由
export const refeshMenu = (params) => {
    return http.get("/sys-menu/refeshMenu", params)
}

// 查找导航菜单树
export const findMenuTree = () => {
    return http.get("/sys-menu/findMenuTree")
}
