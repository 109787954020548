import http from '../utils/request'





// 删除
export const batchDelete = (params) => {
    return http.get("/imitation/delete", params)
}

//获取所有的即将上市产品

export const getRevisingProjectInfo = (params) => {
    return http.get("/imitation/getRevisingProjectInfo", params)
}


export const uploadFile = (data) => {
    const formData =new FormData();
    formData.append("file",data)
    return http.post("/imitation/uploadFile",formData)
}

//新增即将上市产品
export const saveRevisingProjectInfo = (data) => {

    return http.post("/imitation/save", data)
}

export const deletFile = (data) => {
    const formData =new FormData();
    formData.append("filepath",data)
    return http.post("/imitation/deletFile",formData)
}