<template>
    <div>
      <div class="manage-header">
        <el-button type="primary" size="small" @click="() => newInnovativeProject()">
          + 新增创新药产品线</el-button
        >
      </div>
      <el-table max-height="680" :data="tableData" style="width: 100%">
        <el-table-column min-width="15%" prop="icon" label="图标">
          <template slot-scope="scope">
            <img :src="scope.row.icon" min-width="70" height="70" />
          </template>
        </el-table-column>
        <el-table-column min-width="15%" prop="xmName" label="项目名称">
        </el-table-column>
  
        <el-table-column min-width="20%" prop="jsSpName" label="技术水平">
        </el-table-column>
  
        <el-table-column min-width="20%" prop="syzName" label="适用症">
        </el-table-column>
  
  
        <el-table-column min-width="20%" label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
  
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="current"
          :page-sizes="[15, 30, 50, 100, 200]"
          :page-size="15"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
  
      <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        width="50%"
        :before-close="handleClose"
      >
        <!-- 表单信息 -->
        <el-form :model="form" ref="form" label-width="100px">
          <el-form-item v-show="showflag" label="id" prop="id">
            <el-input v-model="form.id"></el-input>
          </el-form-item>
          <el-form-item label="项目名称" prop="xmName">
            <el-input v-model="form.xmName" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="适用症" prop="syzName">
            <el-input v-model="form.syzName" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="技术水平" prop="jsSpName">
            <el-input v-model="form.jsSpName" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="图标" prop="icon">
              <el-upload
              class="avatar-uploader"
              action="#"
              :http-request="uploadProductArea_icon"
              :show-file-list="false"
              
              accept="image/*"
              :on-success="handleAvatarSuccess"
            >
              <img v-if="form.icon" :src="form.icon" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
  
        
  
      
        </el-form>
  
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel">取 消</el-button>
          <!-- <el-button @click="clear">清 除</el-button> -->
          <el-button type="primary" @click="submit">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </template>
                
                <script>
  import {
  
    saveInnovativeProjectInfo,
    getInnovativeProjectInfo,
    deletFile,
    uploadFile,
    batchDelete,
  } from "@/api/innovativeproject";
  import qs from "qs";
  export default {
    methods: {
      newInnovativeProject() {
        this.title = "新增在研仿制药";
        this.action = "new";
        this.clear();
        this.dialogVisible = true;
      },
  
      uploadProductArea_icon(file) {
        uploadFile(file.file).then((res) => {
          if (res.data.code === 200) {
            this.form.icon = res.data.data.filePath;
          }
        });
      },
      tableRowClassName({ row, rowIndex }) {
        if (rowIndex === 1) {
          return "warning-row";
        } else if (rowIndex === 3) {
          return "success-row";
        }
        return "";
      },
  
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.size = val;
        this.current = 1;
        this.getRevisingProjectTableInfo();
      },
      handleCurrentChange(val) {
        this.current = val;
        console.log(`当前页: ${val}`);
        this.getRevisingProjectTableInfo();
      },
      handleRemove(file, fileList) {
        console.log(file, fileList);
      },
  
      submit() {
        let msg = "";
        if (this.action === "new") {
          msg = "确认新增在研仿制药?";
        } else {
          msg = "确认修改在研仿制药信息？";
        }
        this.$refs["form"].validate((valid) => {
          if (valid) {
            this.$confirm(msg, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                // console.log(qs.stringify(this.form));
                const formData = new FormData();
                formData.append("id", this.form.id);
                formData.append("xmName", this.form.xmName);
                formData.append("icon", this.form.icon);
                formData.append("jsSpName", this.form.jsSpName);
                formData.append("syzName", this.form.syzName);
                saveInnovativeProjectInfo(formData).then(({ data }) => {
                  if (data.code === 200) {
                    this.getRevisingProjectTableInfo();
                    this.$message({
                      type: "success",
                      message: "处理成功!",
                    });
                    this.dialogVisible = false;
                    this.form.id = "";
                    this.form.xmName = "";
                    this.form.icon = "";
                    this.form.jsSpName = "";
                    this.form.syzName = "";
                  }
                });
              })
              .catch(() => {});
          } else {
            return false;
          }
        });
  
      },
      handleClose() {
        if (this.action === "new") {
          this.clear();
        }
        this.dialogVisible = false;
      },
  
      clear() {
        let this_ = this;
        this.form.id = "";
        this.form.xmName = "";
        this.form.icon = "";
        this.form.jsSpName = "";
        this.form.syzName = "";
        if (this.action === "edit") {
        } else {
          this.deleteProductFile();
        }
      },
      cancel() {
        this.handleClose();
      },
  
  
      deleteProductFile(){
          if (this.form.icon === ""  ) {
          return;
         }
  
         if (this.form.icon != "") {
          deletFile(this.form.icon).then(({ data }) => {
            if (data.code === 200) {
              this.form.icon = "";
            }
          });
        }
      },
  
  
      getRevisingProjectTableInfo() {
        var this_ = this;
        const page = {
          size: this.size,
          current: this.current,
        };
        const params = { params: page };
        getInnovativeProjectInfo(params).then((res) => {
          //   console.log(res.data, "getCarouselImages");
          if (res.data.code === 200) {
            this.$nextTick(() => {
              this.tableData = res.data.data.records;
              this.total = res.data.data.total;
            });
          }
        });
      },
      updateSort(row) {
        console.log(row, "updateSort");
        const params = { params: { id: row.id, sort: row.sort } };
        updateProjectCommingSort(params).then(({ data }) => {
          if (data.code === 200) {
            this.getRevisingProjectTableInfo();
         
          }
        });
      },
      handlePerm() {},
      handleAvatarSuccess() {},
      handleDelete(index, row) {
        const params = { params: { id: row.id, iconpath: row.icon } };
        this.$confirm("确认删除该条在研仿制药信息？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            batchDelete(params).then(({ data }) => {
              if (data.code === 200) {
                  this.deleteProductFile();
                this.getRevisingProjectTableInfo();
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
              }
            });
          })
          .catch(() => {
            return;
          });
      },
      handleEdit(index, row) {
        this.action = "edit";
        this.title = "编辑在研仿制药信息";
        this.dialogVisible = true;
        this.$nextTick(() => {
          this.form = JSON.parse(JSON.stringify(row));
        });
      },
    },
    mounted() {
      this.getRevisingProjectTableInfo();
      getInnovativeProjectInfo().then((res) => {
        if (res.data.code === 200) {
          this.$nextTick(() => {
            this.productArea = res.data.data;
          });
        }
      });
      // this.form.pId = this.$route.query.id;
      this.$set(this.form, "pId", this.$route.query.id);
      // alert(this.form.pId)
    },
    data() {
      return {
        tableData: [],
        showflag: false,
        form: {
          id: "",
          xmName: "",
          icon: "",
          jsSpName: 0,
          syzName: "",
        },
        editorOption: {},
        title: "",
        action: "",
        dialogVisible: false,
        total: 0,
        size: 15,
        current: 0,
        orders: "",
  
        test: 4,
        productArea: [],
      };
    },
  };
  </script>
                
                <style lang='less' scoped >
  .el-table .warning-row {
    background: oldlace;
  }
  
  .el-table .success-row {
    background: #f0f9eb;
  }
  
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
  }
  .avatar {
    width: 150px;
    height: 150px;
    display: block;
  }
  .ql-editor {
    height: 150px;
  }
  .ql-blank {
    height: 150px;
  }
  .ql-container {
    height: 150px;
  }
  </style>