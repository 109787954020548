<!--  -->
<template>
  <el-menu
    default-active="1-4-1"
    class="el-menu-vertical-demo"
    @open="handleOpen"
    @close="handleClose"
    :collapse="isCollapse"
    background-color="#545c64"
    text-color="#fff"
    active-text-color="#ffd04b"
  >
    <h3>{{ isCollapse ? "后台" : "招商管理系统后台" }}</h3>

    <div v-for="item in menuData" :key="item.label">
      <!-- 一级菜单（没有子级菜单）-->
      <el-menu-item
        @click="clickMenu(item)"
        :index="item.label"
        v-if="item.children.length === 0"
      >
        <i :class="`el-icon-${item.icon}`"></i>
        <span slot="title">{{ item.label }}</span>
      </el-menu-item>
      <!-- 一级菜单（有子菜单）-->
      <el-submenu :index="item.label" v-else>
        <template slot="title">
        
          <i :class="`el-icon-${item.icon}`"></i>
            <span slot="title">{{ item.label }}</span>
        </template>
        <!-- 遍历二级菜单 -->
        <div v-for="(i, index) in item.children" :key="index">
          <!-- 二级菜单（没有三级菜单）-->
          <el-menu-item
            @click="clickMenu(item.children[index])"
            :index="i.path"
            v-if="i.children.length === 0"
          >
            <i :class="`el-icon-${i.icon}`"></i>
            <span slot="title">{{ i.label }}</span>
          </el-menu-item>
          <!-- 二级菜单（有三级菜单）-->
          <el-submenu :index="i.path" v-else>
            <template slot="title">
              <i :class="`el-icon-${i.icon}`"></i>
              <span slot="title">{{ i.label }}</span>
            </template>
            <el-menu-item
              @click="clickMenu(i.children[index])"
              :index="j.path"
              v-for="(j, index) in i.children"
              :key="index"
            >
              <i :class="`el-icon-${j.icon}`"></i>
              <span slot="title">{{ j.label }}</span>
            </el-menu-item>
          </el-submenu>
        </div>
      </el-submenu>
    </div>

    <!-- <el-menu-item @click="clickMenu(item)" v-for="item in noChildren" :key="item.name" :index="item.name">
      <i :class="`el-icon-${item.icon}`"></i>
      <span slot="title">{{item.label}}</span>
    </el-menu-item>
    <el-submenu v-for="item in hasChildren" :key="item.label" :index="item.label">
      <template slot="title">
        <i :class="`el-icon-${item.icon}`"></i>
        <span slot="title">{{item.label}}</span>
      </template>
      <el-menu-item-group v-for="subItem in item.children" :key="subItem.path">
        <el-menu-item @click="clickMenu(subItem)" :index="subItem.path">{{subItem.label}}</el-menu-item>
      </el-menu-item-group>
    </el-submenu> -->
  </el-menu>
</template>
<script>
import Cookies from "js-cookie";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      isCollapse11: false,
    };
  },
  //监听属性 类似于data概念
  computed: {
    //没有子菜单

    noChildren() {
      //  注  this.menuData 指的是调用的menuData（）方法
      return this.menuData.filter((item) => !item.children);
    },
    //有子菜单
    hasChildren() {
      return this.menuData.filter((item) => item.children);
    },
    isCollapse() {
      return this.$store.state.tab.isCollapse;
    },

    menuData() {
  
      return JSON.parse(localStorage.getItem("menu")) || this.$store.state.tab.menu;
    },

    //有子菜单
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    //点击菜单
    clickMenu(item) {

      
      //当页面路由与当前路由不一致时才允许跳转
      if (
        this.$route.path !== item.path &&
        !(this.$route.path === "/home" && item.path === "/")
      ) {
        console.log(item, "clickMenu");
        if(item.path !== null){
          this.$store.commit("selectMenu", item);
          this.$router.push(item.path);
        }
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.el-menu--collapse{
  
  width: 48px;
}
.el-menu {
  height: 100vh;
  border-right: none;
  h3 {
    color: #fff;
    text-align: center;
    line-height: 48px;
    font-size: 15px;
    font-weight: 400px;
  }
}
</style>