import http from '../utils/request'


// 获取轮播图详情，
export const getproductareasInfo = () => {
    return http.get("/productarea/getproductareasInfo")
}


export const uploadFile = (data) => {
    const formData =new FormData();
    formData.append("file",data)
    return http.post("/productarea/uploadFile",formData)
}


export const deletFile = (data) => {
    const formData =new FormData();
    formData.append("filepath",data)
    return http.post("/productarea/deletFile",formData)
}

//轮播图保存
export const saveproductareaInfo = (data) => {
    
    return http.post("/productarea/save", data)
}


// 删除
export const batchDelete = (params) => {
    return http.get("/productarea/delete", params)
}