import http from '../utils/request'





// 删除
export const batchDelete = (params) => {
    return http.get("/year/delete", params)
}

//获取所有的即将上市产品

export const getProjectCommingInfo = (params) => {
    return http.get("/year/getProjectCommingInfo", params)
}

//更新即将上市产品的排序
export const updateProjectCommingSort = (params) => {
    return http.get("/year/updateProjectCommingSort", params)
}

//新增即将上市产品
export const saveProjectCommingInfo = (data) => {

    return http.post("/year/save", data)
}