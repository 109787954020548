<template>
  <div>
    <div class="manage-header">
      <el-button type="primary" size="small" @click="() => newProductArea()">
        + 新增领域</el-button
      >
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="name" label="名称"> </el-table-column>
      <el-table-column prop="title" label="标题"> </el-table-column>
      <el-table-column prop="ztitle" label="子标题"> </el-table-column>
      <el-table-column prop="icon" label="图标">
        <template slot-scope="scope">
          <img :src="scope.row.icon" min-width="70" height="70" />
        </template>
      </el-table-column>
      <el-table-column prop="zlogo" label="子图标">
        <template slot-scope="scope">
          <img :src="scope.row.zlogo" min-width="70" height="70" />
        </template>
      </el-table-column>
      <!-- <el-table-column prop="status" label="角色状态">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{
                  scope.row.status == 1 ? "启用" : "停用"
                }}</span>
              </template>
            </el-table-column> -->

      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" @click="addProduct(scope.row)"
            >新增产品</el-button
          >
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[30, 50, 100, 200]"
        :page-size="30"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <!-- 表单信息 -->
      <el-form :model="form" ref="form" label-width="100px">
        <el-form-item v-show="showflag" label="id" prop="id">
          <el-input v-model="form.id"></el-input>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="标题" prop="title">
          <el-input v-model="form.title" placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-form-item label="子标题" prop="ztitle">
          <el-input v-model="form.ztitle" placeholder="请输入子标题"></el-input>
        </el-form-item>

        <el-form-item label="图标" prop="icon">
          <el-upload
            class="avatar-uploader"
            action="#"
            :http-request="uploadProductArea_icon"
            :show-file-list="false"
            :data="iconUpload"
            accept="image/*"
            :on-success="handleAvatarSuccess"
          >
            <img v-if="form.icon" :src="form.icon" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="子图标" prop="zlogo">
          <el-upload
            class="avatar-uploader"
            action="#"
            :http-request="uploadProductArea_z_logo"
            :show-file-list="false"
            :data="bannerUpload"
            accept="image/*"
            :on-success="handleAvatarSuccess"
          >
            <img v-if="form.zlogo" :src="form.zlogo" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <!-- <el-button @click="clear">清 除</el-button> -->
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
        
        <script>
import {
  getproductareasInfo,
  uploadFile,
  deletFile,
  saveproductareaInfo,
  batchDelete,
} from "@/api/productarea";
import qs from "qs";
export default {
  methods: {
    newProductArea() {
      this.title = "新增产品领域信息";
      this.action = "new";
      this.clear();
      this.dialogVisible = true;
    },

    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === 1) {
        return "warning-row";
      } else if (rowIndex === 3) {
        return "success-row";
      }
      return "";
    },

    beforeUpload(file) {
      // 截取上传文件的后缀名
      let fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
      console.log(fileType);
      // 判断文件名的类型，允许多种就判断多个
      if (fileType !== "jpg" && fileType !== "jpeg" && fileType !== "png") {
        this.$message.warning(`请上传jpg、jpge或png的图片！`);
        this.fileList = [];
        return false;
      }
    },

    // 处理预览操作
    handlePreview(file) {
      console.log(this.fileList[0]);
      console.log(file, "handlePreview");
    },

    uploadProductArea_icon(file) {
      uploadFile(file.file).then((res) => {
        if (res.data.code === 200) {
          this.form.icon = res.data.data.filePath;
        }
      });
    },
    uploadProductArea_z_logo(file) {
      uploadFile(file.file).then((res) => {
        if (res.data.code === 200) {
          this.form.zlogo = res.data.data.filePath;
        }
      });
    },

    // 处理超出图片个数操作
    handleExceed(files, fileList) {
      this.$message.warning(
        `只能上传一个文件，若需要上传，请先删除之前上传的文件，再上传新的图片`
      );
    },

    // 移除之前的操作
    beforeRemove(file, fileList) {
      if (this.fileList.length === 0) {
        return;
      }
      console.log(fileList[0], "filelist");
      this.$confirm(`确定移除 ${file.name}？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.deleteProductFile();
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消修改",
          // });
        });

      return false;
    },

    deleteProductFile() {
      if (this.form.icon === "" && this.form.zlogo === "") {
        return;
      }
      if (this.form.icon != "") {
        deletFile(this.form.icon).then(({ data }) => {
          if (data.code === 200) {
            this.form.icon = "";
          }
        });
      }

      if (this.form.zlogo != "") {
        deletFile(this.form.zlogo).then(({ data }) => {
          if (data.code === 200) {
            this.form.zlogo = "";
          }
        });
      }
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },

    addProduct(row) {
      const item = {
        id: 21,
        menuName: "productsInfo",
        path: "/productsInfo",
        component: "ProductsInfo.vue",
        visible: "0",
        status: "0",
        perms: "*",
        icon: "setting",
        label: "产品列表",
        parentMenuId: 18,
        level: 1,
        createBy: null,
        createTime: null,
        updateBy: null,
        updateTime: null,
        delFlag: 0,
        remark: null,
        children: [],
      };
      this.$store.commit("selectMenu", item);
      this.$router.push("/productsInfo?id=" + row.id);
    },
    submit() {
      let msg = "";
      if (this.action === "new") {
        msg = "确认新增产品领域?";
      } else {
        msg = "确认修改产品领域信息？";
      }
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$confirm(msg, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              saveproductareaInfo(qs.stringify(this.form)).then(({ data }) => {
                if (data.code === 200) {
                  this.getProductAreaInfo();
                  this.$message({
                    type: "success",
                    message: "处理成功!",
                  });
                  this.dialogVisible = false;
                  this.form.id = "";
                  this.form.name = "";
                  this.form.icon = "";
                  this.form.title = "";
                  this.form.logo = "";
                  this.form.zlogo = "";
                  this.form.ztitle = "";
                }
              });
            })
            .catch(() => {});
        } else {
          return false;
        }
      });

      // this.dialogVisible = false;
    },
    handleClose() {
      if (this.action === "new") {
        this.deleteProductFile();
        this.clear();
      }
      this.dialogVisible = false;
    },
    uploadBannerFile() {},
    clear() {
      let this_ = this;
      this.form.id = "";
      this.form.name = "";
      this.form.icon = "";
      this.form.title = "";
      this.form.logo = "";
      this.form.zlogo = "";
      this.form.ztitle = "";
      if (this.action === "edit") {
      } else {
        this.deleteProductFile();
      }
    },
    cancel() {
      this.handleClose();
    },
    getProductAreaInfo() {
      var this_ = this;
      getproductareasInfo().then((res) => {
        //   console.log(res.data, "getCarouselImages");
        if (res.data.code === 200) {
          this.$nextTick(() => {
            this.tableData = res.data.data.records;
            this.total = res.data.data.total;
          });
          // console.log(this.tableData,"tableData")
        }
      });
    },

    handlePerm() {},
    handleAvatarSuccess() {},
    handleDelete(index, row) {
      const params = { params: { id: row.id } };
      this.$confirm("确认删除该条产品领域信息？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          batchDelete(params).then(({ data }) => {
            if (data.code === 200) {
              this.deleteProductFile();
              this.getProductAreaInfo();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          return;
        });
    },
    handleEdit(index, row) {
      this.action = "edit";
      this.title = "编辑产品领域信息";
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.form = JSON.parse(JSON.stringify(row));
      });
    },
  },
  mounted() {
    this.getProductAreaInfo();
  },
  data() {
    return {
      tableData: [],
      showflag: false,
      form: {
        id: "",
        name: "",
        title: "",
        icon: "",
        logo: "",
        ztitle: "",
        zlogo: "",
      },
      title: "",
      bannerUpload: { type: "bannner" },
      iconUpload: { type: "url" },
      bodyUpload: { type: "body" },
      fileList: [],
      action: "",
      dialogVisible: false,
      total: 0,
      size: "",
      current: 0,
      orders: "",
    };
  },
};
</script>
        
        <style lang='less' scoped >
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>