import http from '../utils/request'





// 删除
export const batchDelete = (params) => {
    return http.get("/innovate/delete", params)
}

//获取所有的即将上市产品

export const getInnovativeProjectInfo = (params) => {
    return http.get("/innovate/getInnovativeProjectInfo", params)
}


export const uploadFile = (data) => {
    const formData =new FormData();
    formData.append("file",data)
    return http.post("/innovate/uploadFile",formData)
}

//新增即将上市产品
export const saveInnovativeProjectInfo = (data) => {

    return http.post("/innovate/save", data)
}

export const deletFile = (data) => {
    const formData =new FormData();
    formData.append("filepath",data)
    return http.post("/innovate/deletFile",formData)
}