import Cookie from 'js-cookie'
export default {
    state: {
        isCollapse: false,//控制菜单的展开与收起
        tabsList: [{
            path: "/",
            name: "home",
            label: "首页",
            icon: "s-home",
            url: "Home/Home",
        }],
        menu: [],
        route: []

    },

    mutations: {
        collapseMenu(state) {
            state.isCollapse = !state.isCollapse
        },
        //更新面包屑数据

        // selectMenu(state, val){
        //     if(val.name !== 'home'){

        //        const index = state.tabsList.findIndex(item=>item.name ===val.name)
        //        if(index === -1){
        //             state.tabsList.push(val)
        //        }
        //     }

        // },
        selectMenu(state, val) {

            if (val.menuName !== 'home') {

                const index = state.tabsList.findIndex(item => item.name === val.menuName)
                if (index === -1) {
                    const itemmenu = {};
                    itemmenu.path = val.path
                    itemmenu.name = val.menuName
                    itemmenu.label = val.label
                    itemmenu.url = val.path
                    state.tabsList.push(itemmenu)
                }
            }

        },
        closeTag(state, item) {
            const index = state.tabsList.findIndex(val => val.name === item.name)
            state.tabsList.splice(index, 1)
        },
        setMenu(state, val) {
            state.menu = val
            // console.info(JSON.stringify(val))
            // Cookie.set("menu", JSON.stringify(val).slice(0, 100))
            localStorage.setItem("menu",JSON.stringify(val))
        },

        // //动态注册路由
        // setRoute(state,router){

        //    if(!Cookie.get("menu"))  return
        //    const route = JSON.parse(Cookie.get("menu"))
        //    console.log(route,"route")
        //    state.menu = route
        //    const routeArr = []
        //    route.forEach(item => {
        //         console.log(item.children.length, item.label,'item')
        //         if(item.children !==0){

        //             item.children= item.children.map(childItem=>{
        //                 childItem.component = ()=> import(`../views/${childItem.component}`)
        //                 return childItem;
        //             })
        //             routeArr.push(...item.children)

        //         }else{
        //             item.component = ()=> import(`../views/${item.component}`)
        //             routeArr.push(item)
        //         }


        //    });
        //    console.log(routeArr ,"routeArr")
        //    routeArr.forEach(item=>{
        //         //main  为/router/index.js下的主路由的名称
        //     router.addRoute('main',item)
        //    })

        // }


        //动态注册路由
        setRoute(state, router) {

            // if (!Cookie.get("route")) return
            if (!localStorage.getItem("route")) return
            const routes = JSON.parse(localStorage.getItem("route"))
            const componentitem = {};
            routes.forEach(item => {
                if(item.path !== null){
                    componentitem.path = item.path;

                    componentitem.name = item.name;
                    componentitem.component =()=> import(`../views/${item.component}`)
                    router.addRoute('main', componentitem)
                }
                
            });
   

        },




    }
}