import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from "./router";
import store from "./store";
import Mock from './api/mock'
import Cookie from 'js-cookie'
import QuillEditor from 'vue-quill-editor';
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(QuillEditor)


new Vue({
  router,store,
  render: h => h(App),
  created(){
    store.commit("setRoute",router)
  }
}).$mount('#app')
