import { render, staticRenderFns } from "./ProductRecommend.vue?vue&type=template&id=a7c46478&scoped=true"
import script from "./ProductRecommend.vue?vue&type=script&lang=js"
export * from "./ProductRecommend.vue?vue&type=script&lang=js"
import style0 from "./ProductRecommend.vue?vue&type=style&index=0&id=a7c46478&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a7c46478",
  null
  
)

export default component.exports