import Mock from 'mockjs'

let List = []

export default {

    getStatisticalData: () => {

        for (let i = 0; i < 7; i++) {
            List.push(

                Mock.mock({
                    苹果: Mock.Random.float(100, 8000, 0, 0),
                    vivo: Mock.Random.float(100, 8000, 0, 0),
                    oppo: Mock.Random.float(100, 8000, 0, 0),
                    xiaomi: Mock.Random.float(100, 8000, 0, 0),
                    huawei: Mock.Random.float(100, 8000, 0, 0),
                    sanxin: Mock.Random.float(100, 8000, 0, 0)
                })
            )
        }

        return {
            code: 2000,
            data: {
                //饼图
                videoData: [
                    {
                        name: 'xiaomi', value: 2999
                    },
                    {
                        name: 'vivo', value: 4444
                    },
                    {
                        name: 'oppo', value: 5555
                    },
                    {
                        name: '苹果', value: 7999
                    },
                    {
                        name: 'huawei', value: 9999
                    },
                    {
                        name: 'sanxin', value: 2999
                    },

                ],
                //柱状图
                userData: [
                    {
                        date: '周一',
                        new: 5,
                        active: 300
                    },
                    {
                        date: '周二',
                        new: 15,
                        active: 600
                    },
                    {
                        date: '周三',
                        new: 25,
                        active: 500
                    },
                    {
                        date: '周四',
                        new: 35,
                        active: 1400
                    },
                    {
                        date: '周五',
                        new: 45,
                        active: 4300
                    },
                    {
                        date: '周六',
                        new: 65,
                        active: 2300
                    },
                    {
                        date: '周日',
                        new: 75,
                        active: 1300
                    }


                ],
                //折线图
                orderData: {
                    date: ['20191001', '20191002', '20191003', '20191004', '20191005', '20191006', '20191007'],
                    data: List
                },
                tableData: [
                    {
                        name: 'oppo',
                        todayBuy: 500,
                        monthBuy: 2500,
                        totalBuy: 30000

                    },
                    {
                        name: 'vivo',
                        todayBuy: 500,
                        monthBuy: 1500,
                        totalBuy: 20000

                    },
                    {
                        name: 'sanxin',
                        todayBuy: 50,
                        monthBuy: 190,
                        totalBuy: 2000

                    },
                    {
                        name: 'huawei',
                        todayBuy: 503,
                        monthBuy: 1540,
                        totalBuy: 20500

                    },
                    {
                        name: '苹果',
                        todayBuy: 900,
                        monthBuy: 5500,
                        totalBuy: 40000

                    },
                    {
                        name: 'xiaomi',
                        todayBuy: 1500,
                        monthBuy: 2500,
                        totalBuy: 30000

                    }
                ]

            }

        }
    }


}