<template>
  <div class="custom-tree-container">
    <div class="block">
      <div class="manage-header">
        <el-button type="primary" size="small" @click="() => newmenu()">
          + 新增</el-button
        >
      </div>
      <p></p>
      <p>菜单</p>
      <el-tree
        :data="menuData"
        show-checkbox
        node-key="id"
        default-expand-all
        :expand-on-click-node="false"
      >
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span>{{ node.label }}</span>
          <span>
            <el-button type="text" size="mini" @click="() => editmenu(data)">
              Edit
            </el-button>
            <el-button type="text" size="mini" @click="() => append(data)">
              Append
            </el-button>
            <el-button
              type="text"
              size="mini"
              @click="() => remove(node, data)"
            >
              Delete
            </el-button>
          </span>
        </span>
      </el-tree>
    </div>

    <el-dialog
      title="菜单"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <!-- 表单信息 -->
      <el-form
        :model="form"
        ref="form"
        :rules="rules"
        :inline="true"
        label-width="100px"
      >
        <el-form-item label="id" prop="id">
          <el-input v-model="form.id" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="菜单名" prop="menuName">
          <el-input
            v-model="form.menuName"
            placeholder="请输入菜单名"
          ></el-input>
        </el-form-item>
        <el-form-item label="路由地址" prop="path">
          <el-input v-model="form.path" placeholder="请输入路由地址"></el-input>
        </el-form-item>

        <el-form-item label="组件路径" prop="component">
          <el-input
            v-model="form.component"
            placeholder="请输入组件路径"
          ></el-input>
        </el-form-item>

        <el-form-item label="权限标识" prop="perms">
          <el-input
            v-model="form.perms"
            placeholder="请输入权限标识"
          ></el-input>
        </el-form-item>

        <el-form-item label="菜单图标" prop="icon">
          <el-input v-model="form.icon" placeholder="请输入菜单图标"></el-input>
        </el-form-item>

        <el-form-item label="前端显示标题" prop="label">
          <el-input
            v-model="form.label"
            placeholder="请输入前端显示标题"
          ></el-input>
        </el-form-item>

        <el-form-item label="上级菜单ID " prop="parentMenuId">
          <el-input
            v-model="form.parentMenuId"
            placeholder="请输入上级菜单ID"
          ></el-input>
        </el-form-item>

        <el-form-item label="菜单层级 " prop="level">
          <el-input
            v-model="form.level"
            placeholder="请输入菜单层级"
          ></el-input>
        </el-form-item>
        <el-form-item label="菜单状态 ">
          <el-select v-model="form.visible" placeholder="请选择活动区域">
            <el-option label="显示" value="0"></el-option>
            <el-option label="隐藏" value="1"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="菜单状态 ">
          <el-select v-model="form.status" placeholder="请选择活动区域">
            <el-option label="正常" value="0"></el-option>
            <el-option label="停用" value="1"></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button @click="clear">清 除</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { findMenuTree, saveMenu, refeshMenu, batchDelete } from "@/api/menu";
import * as dd from "dingtalk-jsapi";
import Cookie from "js-cookie";
import qs from "qs";
let id = 1000;

export default {
  data() {
    return {
      form: {
        id: "",
        menuName: "",
        path: "",
        component: "",
        visible: "",
        status: "",
        perms: "",
        icon: "",
        label: "",
        parentMenuId: "",
        level: "",
      },
      menuData: [],
      dialogVisible: false,
      action: "",
      rules: {
        // menuName: [
        //   { required: true, message: "请输入菜单名称", trigger: "blur" },
        //   { min: 3, max: 8, message: "长度在 2 到 8个字符", trigger: "blur" },
        // ],
        // path: [
        //   { required: true, message: "请输入路由路径", trigger: "change" },
        // ],
        // component: [
        //   { required: true, message: "请输入组件", trigger: "change" },
        // ],
        // perms: [{ required: true, message: "请输入权限", trigger: "change" }],
        // icon: [{ required: true, message: "请输入图标", trigger: "change" }],
        // label: [{ required: true, message: "请输入标题", trigger: "change" }],
      },
    };
  },

  methods: {
    handleClose() {
      // this.resetForm("menuform");
      // this.$refs["form"].resetFields();
      if (this.$refs["form"] !== undefined) {
        this.$refs["form"].resetFields();
      }
      this.dialogVisible = false;
    },
    cancel() {
      this.handleClose();
    },
    editmenu(data) {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.form = JSON.parse(JSON.stringify(data));
      });

      this.action = "edit";
    },
    refeshMenu(userid) {
      const params = { params: { userId: userid } };
      refeshMenu(params).then(({ data }) => {
        if (data.code === 200) {
          this.$store.commit("setMenu", data.data.menu);
          // const route = JSON.parse(Cookie.get("menu"));
          const route = JSON.parse(localStorage.getItem("menu"));
          const routeArr = [];
          route.forEach((item) => {
            this.dealMenu(item, routeArr);
          });
          // Cookie.set("route", JSON.stringify(routeArr));
          localStorage.setItem("route", JSON.stringify(routeArr));
          this.$store.state.tab.route = routeArr;
          this.$store.commit("setRoute", this.$router);
          // this.$store.dispatch("menu");
          const menuitem= {menuName:'menu'}
          this.$store.commit("selectMenu", menuitem);
        }
      });
    },
    //处理路由的方法
    dealMenu(item, dataArr) {
      // console.log(item,"item.....")
      if (typeof item === "undefined") {
        return;
      }
      if (item.children.length === 0) {
        dataArr.push(item);
      } else {
        item.children.forEach((childitem) => {
          this.dealMenu(childitem, dataArr);
        });
      }
    },
    getMenuTree() {
      findMenuTree().then(({ data }) => {
        if (data.code === 200) {
          //对返回的菜单数据进行处理

          this.$nextTick(() => {
            this.menuData = data.data;
          });

          // alert(this.menuData);
        }
      });
    },
    newmenu() {
      this.dialogVisible = true;
      this.form.parentMenuId = 0;
      this.form.level = 0;
      this.action = "new";
    },
    append(data) {
      this.dialogVisible = true;
      // this.resetForm("form");

      this.form.parentMenuId = data.id;
      this.form.level = data.level + 1;
      this.action = "new";
    },
    clear() {
      this.$refs["form"].resetFields();
    },

    remove(node, data) {
      console.log(node, "node");
      console.log(data.id, "data");
      this.action = "delete";


      this.$confirm("确认删除该菜单?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              const params = { params: { id: data.id } };
              batchDelete(params).then(({ data }) => {
                if (data.code === 200) {
                  this.getMenuTree();
                  this.refeshMenu(Cookie.get("userid"));
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                }
              });

              this.dialogVisible = false;
            })
            .catch(() => {
              // this.$message({
              //   type: "info",
              //   message: "已取消修改",
              // });
            });
      // const params = { params: { id: userid } };
      // batchDelete().then(({ data }) => {});
      // const parent = node.parent;
      // const children = parent.data.children || parent.data;
      // const index = children.findIndex((d) => d.id === data.id);
      // children.splice(index, 1);
    },
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let msg = ""
          if(this.action === 'new'){
            msg = "确认新增菜单信息?"
          }else{
            msg = '确认修改菜单信息?';
          }
          this.$confirm(msg, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => { 
              const formData =new FormData();
              formData.append("id",this.form.id)
              formData.append("menuName",this.form.menuName)
              formData.append("path",this.form.path)
              formData.append("component",this.form.component)
              formData.append("visible",this.form.visible)
              formData.append("status",this.form.status)
              formData.append("perms",this.form.perms)
              formData.append("icon",this.form.icon)
              formData.append("label",this.form.label)
              formData.append("parentMenuId",this.form.parentMenuId)
              formData.append("level",this.form.level)
              // formData.append("delFlag",this.form.delFlag)
              // formData.append("remark",this.form.remark)



              console.log(formData)
              saveMenu(formData).then(({ data }) => {
                if (data.code === 200) {
                  this.getMenuTree();
                  this.refeshMenu(Cookie.get("userid"));
                  this.$message({
                    type: "success",
                    message: "修改成功!",
                  });
                }
              });

              this.dialogVisible = false;
            })
            .catch(() => {
              // this.$message({
              //   type: "info",
              //   message: "已取消修改",
              // });
            });
        } else {
          return false;
        }
      });
    },

    renderContent(h, { node, data, store }) {
      return (
        <span class="custom-tree-node">
          <span>{node.label}</span>
          <span>
            <el-button
              size="mini"
              type="text"
              on-click={() => this.append(data)}
            >
              Append
            </el-button>
            <el-button
              size="mini"
              type="text"
              on-click={() => this.remove(node, data)}
            >
              Delete
            </el-button>
          </span>
        </span>
      );
    },
  },
  mounted() {
    this.getMenuTree();
  },
};
</script>

<style lang='scss' scoped>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.el-select {
  width: 204px;
}
</style>