<!--  -->
<template>
  <div class="manage">
    <el-dialog title="提示" :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
      <!-- 表单信息 -->
      <el-form :model="form" ref="form" :rules="rules" :inline="true" label-width="80px">
        <el-form-item label="姓名"  prop="name">
          <el-input
            v-model="form.name"
             
            placeholder="请输入姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="年龄"  prop="age">
          <el-input
            v-model="form.age"
            
            placeholder="请输入年龄"
          ></el-input>
        </el-form-item>

        <el-form-item label="性别" prop="sex">
          <el-select v-model="form.sex" placeholder="请选择性别">
            <el-option label="男" value="1"></el-option>
            <el-option label="女" value="0"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="出生日期" prop="birth">
          <el-date-picker
            v-model="form.birth"
            type="datetime"
            placeholder="选择出生日期"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="地址" prop="addr">
          <el-input
            v-model="form.addr"
         
            placeholder="请输入地址"
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <div class="manage-header">
      <el-button type="primary" @click="dialogVisible = true">
        + 新增</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      dialogVisible: false,
      form: {
        name: "",
        age: "",
        sex: "",
        birth: "",
        addr: "",
      },

      rules: {
          name: [
            { required: true, message: '请输入姓名', trigger: 'blur' },
            { min: 3, max: 8, message: '长度在 2 到 8个字符', trigger: 'blur' }
          ],
          addr: [
            { required: true, message: '请输入地址', trigger: 'change' }
          ],
          birth: [
            {   required: true, message: '请选择日期', trigger: 'change' }
          ],
           
          sex: [
            {   required: true, message: '请选择性别', trigger: 'change' }
          ],
          age: [
            { required: true, message: '请输入年龄', trigger: 'change' }
          ],
         
        }
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    handleClose(done) {
      this.$refs['form'].resetFields();
          this.dialogVisible = false
    },
    cancel(){
      this.handleClose()
    },

    submit(){
      this.$refs['form'].validate((valid) => {
          if (valid) {
            alert('submit!');
            //后台处理数据


            //情况表单数据

            this.$refs['form'].resetFields();
            this.dialogVisible = false
          } else {
            // console.log('error submit!!');
            return false;
          }
        });
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
</style>