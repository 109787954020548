import http from '../utils/request'



export const getColumnsImages = () => {
    return http.get("/column/getColumnImages")
}


export const uploadFile = (data) => {
    const formData =new FormData();
    formData.append("file",data)
    return http.post("/column/uploadFile",formData)
}


export const deletFile = (data) => {
    const formData =new FormData();
    formData.append("filepath",data)
    return http.post("/column/deletFile",formData)
}

//轮播图保存
export const saveColumnInfo = (data) => {
    
    return http.post("/column/save", data)
}


// 删除
export const batchDelete = (params) => {
    return http.get("/column/delete", params)
}