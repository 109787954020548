<template>
  <div>
    <div class="manage-header">
      <el-input
        placeholder="请输入产品名称"
        v-model="keyword"
        clearable 
        class="input-with-select"
        style="width: 300px"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="searchByKeyword"
        ></el-button>
      </el-input>
    </div>
    <el-table max-height="700" :data="tableData" style="width: 100%">
      <el-table-column min-width="15%" prop="name" label="产品名称">
      </el-table-column>
      <el-table-column min-width="15%" prop="icon" label="产品图片">
        <template slot-scope="scope">
          <img :src="scope.row.icon" min-width="70" height="70" />
        </template>
      </el-table-column>

      <el-table-column min-width="30%" prop="logo" label="公司LOGO">
        <template slot-scope="scope">
          <img :src="scope.row.logo" min-width="70" height="70" />
        </template>
      </el-table-column>

      <el-table-column min-width="20%" label="是否推荐">
        <template slot-scope="scope">
          <el-switch
            @change="recommend(scope.row)"
            v-model="scope.row.recommend"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            active-text="推荐"
            :inactive-value="0"
            inactive-text="不推荐"
          ></el-switch>
        </template>
      </el-table-column>
    </el-table>

    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[15, 30, 50, 100, 200]"
        :page-size="15"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
            
            <script>
import {
  getproductsInfo,
  uploadFile,
  deletFile,
  updateProductRecommendStatus,
  saveproductInfo,
  getProductArea,
  batchDelete,
} from "@/api/product";
import qs from "qs";
export default {
  methods: {
    newProductArea() {
      this.title = "新增产品信息";
      this.action = "new";
      this.clear();
      this.dialogVisible = true;
    },

    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === 1) {
        return "warning-row";
      } else if (rowIndex === 3) {
        return "success-row";
      }
      return "";
    },

    beforeUpload(file) {
      // 截取上传文件的后缀名
      let fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
      console.log(fileType);
      // 判断文件名的类型，允许多种就判断多个
      if (fileType !== "jpg" && fileType !== "jpeg" && fileType !== "png") {
        this.$message.warning(`请上传jpg、jpge或png的图片！`);
        this.fileList = [];
        return false;
      }
    },

    // 处理预览操作
    handlePreview(file) {
      console.log(this.fileList[0]);
      console.log(file, "handlePreview");
    },

    //上传产品图片
    uploadProductIcon(file) {
      uploadFile(file.file).then((res) => {
        if (res.data.code === 200) {
          this.form.icon = res.data.data.filePath;
        }
      });
    },
    //公司logo
    uploadProductLogo(file) {
      uploadFile(file.file).then((res) => {
        if (res.data.code === 200) {
          this.form.logo = res.data.data.filePath;
        }
      });
    },

    //产品详情图
    uploadProductHtml(file) {
      uploadFile(file.file).then((res) => {
        if (res.data.code === 200) {
          this.form.html = res.data.data.filePath;
        }
      });
    },

    recommend(row) {
      console.log(row, "row");
      //   console.log(row.recommend)
      if (row.recommend) {
        this.form.recommend = 1;
        // console.log("推荐")
      } else {
        this.form.recommend = 0;
        // console.log("bu推荐")
      }

      const params = { params: { id: row.id, recommend: this.form.recommend } };
      updateProductRecommendStatus(params).then((res) => {
        //   console.log(res.data, "getCarouselImages");
        if (res.data.code === 200) {
          this.$nextTick(() => {
            this.getProductTableInfo(this.productAreaId);
          });
        }
      });
    },

    // 处理超出图片个数操作
    handleExceed(files, fileList) {
      this.$message.warning(
        `只能上传一个文件，若需要上传，请先删除之前上传的文件，再上传新的图片`
      );
    },

    quillEditorFocus() {},

    // 移除之前的操作
    beforeRemove(file, fileList) {
      if (this.fileList.length === 0) {
        return;
      }
      // console.log(fileList[0], "filelist");
      this.$confirm(`确定移除 ${file.name}？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.deleteProductFile();
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消修改",
          // });
        });

      return false;
    },

    deleteProductFile() {
      if (
        this.form.icon === "" &&
        this.form.logo === "" &&
        this.form.html === ""
      ) {
        return;
      }
      if (this.form.icon != "") {
        deletFile(this.form.icon).then(({ data }) => {
          if (data.code === 200) {
            this.form.icon = "";
          }
        });
      }

      if (this.form.logo != "") {
        deletFile(this.form.logo).then(({ data }) => {
          if (data.code === 200) {
            this.form.logo = "";
          }
        });
      }

      if (this.form.html != "") {
        deletFile(this.form.html).then(({ data }) => {
          if (data.code === 200) {
            this.form.html = "";
          }
        });
      }
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.size = val;
      this.current = 1;
      this.getProductTableInfo(this.productAreaId);
    },
    handleCurrentChange(val) {
      this.current = val;
      console.log(`当前页: ${val}`);
      this.getProductTableInfo(this.productAreaId);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },

    submit() {
      let msg = "";
      if (this.action === "new") {
        msg = "确认新增产品?";
      } else {
        msg = "确认修改产品信息？";
      }
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$confirm(msg, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.form.pId = this.productAreaId;
              saveproductInfo(qs.stringify(this.form)).then(({ data }) => {
                if (data.code === 200) {
                  this.getProductTableInfo(this.productAreaId);
                  this.$message({
                    type: "success",
                    message: "处理成功!",
                  });
                  this.dialogVisible = false;
                  this.form.id = "";
                  this.form.icon = "";
                  this.form.describe = "";
                  this.form.pId = "";
                  this.form.html = "";
                  this.form.logo = "";
                  this.form.name = "";
                  this.form.sort = "";
                  this.form.recommend = "";
                }
              });
            })
            .catch(() => {});
        } else {
          return false;
        }
      });

      // this.dialogVisible = false;
    },
    handleClose() {
      if (this.action === "new") {
        this.deleteProductFile();
        this.clear();
      }
      this.dialogVisible = false;
    },

    clear() {
      let this_ = this;
      this.form.id = "";
      this.form.icon = "";
      this.form.describe = "";
      this.form.pId = "";
      this.form.html = "";
      this.form.logo = "";
      this.form.name = "";
      this.form.sort = "";
      this.form.recommend = "";
      if (this.action === "edit") {
      } else {
        this.deleteProductFile();
      }
    },
    cancel() {
      this.handleClose();
    },
    getProductTableInfo(productAreaId) {
      var this_ = this;
      let page = {};
      if (this.keyword === "") {
        page = {
          size: this.size,
          current: this.current,
          productAreaId: productAreaId,
        };
      } else {
        page = {
          size: this.size,
          current: this.current,
          productAreaId: productAreaId,
          keyword: this.keyword,
        };
      }
      const params = { params: page };
      getproductsInfo(params).then((res) => {
        //   console.log(res.data, "getCarouselImages");
        if (res.data.code === 200) {
          this.$nextTick(() => {
            this.tableData = res.data.data.records;
            this.total = res.data.data.total;
          });
        }
      });
    },
    searchByKeyword() {
      console.log(this.keyword, "ke");
      console.log("1111");
      this.getProductTableInfo(this.productAreaId);
    },
    handlePerm() {},
    handleAvatarSuccess() {},
    handleDelete(index, row) {
      const params = { params: { id: row.id } };
      this.$confirm("确认删除该条产品信息？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          batchDelete(params).then(({ data }) => {
            if (data.code === 200) {
              this.deleteProductFile();
              this.getProductTableInfo(this.productAreaId);
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          return;
        });
    },
    handleEdit(index, row) {
      this.action = "edit";
      this.title = "编辑产品信息";
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.form = JSON.parse(JSON.stringify(row));
      });
    },
  },
  mounted() {
    this.productAreaId = this.$route.query.id;
    this.getProductTableInfo(this.productAreaId);
    getProductArea().then((res) => {
      if (res.data.code === 200) {
        this.$nextTick(() => {
          this.productArea = res.data.data;
        });
      }
    });
    // this.form.pId = this.$route.query.id;
    this.$set(this.form, "pId", this.$route.query.id);
    // alert(this.form.pId)
  },
  data() {
    return {
      tableData: [],
      showflag: false,
      form: {
        id: "",
        icon: "",
        describe: "",
        pId: 0,
        html: "",
        logo: "",
        name: "",
        sort: "",
        recommend: "",
      },
      editorOption: {},
      keyword: "",
      title: "",
      action: "",
      dialogVisible: false,
      total: 0,
      size: 15,
      current: 0,
      orders: "",
      productAreaId: "",
      test: 4,
      productArea: [],
    };
  },
};
</script>
            
            <style lang='less' scoped >
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 150px;
  text-align: center;
}
.avatar {
  width: 150px;
  height: 150px;
  display: block;
}
.ql-editor {
  height: 150px;
}
</style>