import http from '../utils/request'

import qs from "qs";

export const login=(data)=>{

    return http.post("/sys-user/login",data)
}





export const getData = () => {
    //返回一个Promise对象
    return http.get("/home/getData")

}
// //获取用户数据

export const getUser = (params) => {

    return http.get("/user/getuser", params)
}


export const dingdingLogin=(params)=>{
    return http.get("/sys-user/dingdingLogin", params)
}



//获取新的token值

export    const refreshToken = (params)=>{
    return  http.get("/sys-user/refreshToken", params)
}


//新增用户， 注 post请求时，参数为data
export const addUser = (data) => {

    return http.post("/user/adduser", data)
}

export const getMenu =(data)=>{

    return http.post('/permission/getMenu',data)
}
