<!--  -->
<template>
  <el-form
    ref="form"
    :inline="true"
    label-width="70px"
    class="login_container"
    :model="form"
    :rules="rules"
  >
    <h3 class="login_title">系统登录</h3>
    <el-form-item label="用户名" prop="userName">
      <el-input v-model="form.userName" placeholder="请输入账号"></el-input>
    </el-form-item>
    <el-form-item label="密码" prop="password">
      <el-input
        type="password"
        v-model="form.password"
        placeholder="请输入密码"
      ></el-input>
    </el-form-item>

    <el-form-item>
      <el-button
        style="margin-left: 105px; margin-top: 10px"
        @click="submit"
        type="primary"
        >登录</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
import Mock from "mockjs";
import Cookie from "js-cookie";
import { getMenu, login, dingdingLogin } from "@/api";
import * as dd from "dingtalk-jsapi";
import { initDingH5RemoteDebug } from "dingtalk-h5-remote-debug";
import qs from "qs";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      form: {
        userName: "",
        password: "",
      },
      rules: {
        userName: {
          required: true,
          trigger: "blur",
          message: "请输入用户名",
        },
        password: {
          required: true,
          trigger: "blur",
          message: "请输入密码",
        },
      },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    submit() {
      //校验form表单
      this.$refs["form"].validate((valid) => {
        if (valid) {
          // 登录
          login(qs.stringify(this.form)).then(({ data }) => {
            // if (data) console.log(data, "登录");
            if (data.code == 500) {
              this.$message.error(data.msg);
              return false;
            } else {
              // console.log(data.data.to
              Cookie.set("token", data.data.token);
              Cookie.set("userid", data.data.userId);
              //获取菜单的数据 存入store中
              this.$store.commit("setMenu", data.data.menu);
              // console.log(Cookie.get("menu"),'menu')
              // const route = JSON.parse(Cookie.get("menu"));

              const route = JSON.parse(localStorage.getItem("menu"));
              const routeArr = [];
              route.forEach((item) => {
                this.dealMenu(item, routeArr);
              });
              // Cookie.set("route", JSON.stringify(routeArr));
              localStorage.setItem("route", JSON.stringify(routeArr));
              this.$store.state.tab.route = routeArr;
              // 设置动态路由
              this.$store.commit("setRoute", this.$router);
              //跳转到主页
              this.$router.push("/home");
            }
          });

          //情况表单数据
        } else {
          this.$message.error("用户名密码错误");
          console.log("error submit!!");
          return false;
        }
      });

      // const token = Mock.Random.guid()
      // console.log(token,"token")
    },

    //处理路由的方法
    dealMenu(item, dataArr) {
      // console.log(item,"item.....")
      if (typeof item === "undefined") {
        return;
      }
      if (item.children.length === 0) {
        dataArr.push(item);
      } else {
        item.children.forEach((childitem) => {
          this.dealMenu(childitem, dataArr);
        });
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    // initDingH5RemoteDebug();
    // // alert(1111)
    // var this_ = this;
    // // alert(111)
    // //初始化去请求钉钉
    // dd.ready(function () {
    //   dd.runtime.permission.requestAuthCode({
    //     corpId: "ding524aa3b626cbd442",
    //     onSuccess: function (result) {
    //       // alert(qs.stringify(result) + "dd.success调用成功");
    //       // alert(result.code);

    //       const params = { params: { code: result.code } };
    //       dingdingLogin(params).then(({ data }) => {
    //         if (data.code == 500) {
    //           this_.$message.error(data.msg);
    //           return false;
    //         } else {
    //           Cookie.set("token", data.data.token);
    //           //获取菜单的数据 存入store中
    //           this_.$store.commit("setMenu", data.data.menu);
    //           // const route = JSON.parse(Cookie.get("menu"));
    //           const route = JSON.parse(localStorage.getItem("menu"));
    //           const routeArr = [];
    //           route.forEach((item) => {
    //             this_.dealMenu(item, routeArr);
    //           });
    //           // Cookie.set("route", JSON.stringify(routeArr));
    //           localStorage.setItem("route", JSON.stringify(routeArr));
    //           this_.$store.state.tab.route = routeArr;
    //           this_.$store.commit("setRoute", this_.$router);
    //           //跳转到主页
    //           this_.$router.push("/home");
    //         }
    //       });
    //       console.log(result, "dd.success调用成功");
    //     },
    //     onFail: function (err) {
    //       console.log(err, "dd.success调用失败");
    //     },
    //   });
    //   dd.error(function (err) {
    //     console.log(err, "错误回调处理");
    //   });
    // });
    // // dingdingLogin().then(({ data }) => {
    // //   console.log(data, "dingdingLogin");
    // // });
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.login_container {
  width: 350px;
  border: 1px solid #eaeaea;
  margin: 180px auto;
  padding: 35px 25px 15px 35px;
  background-color: #fff;
  box-shadow: 0 0 25px #cac6c6;
  border-radius: 15px;
  box-sizing: border-box;
  .el-input {
    width: 198px;
  }
  .login_title {
    text-align: center;
    margin-bottom: 40px;
    color: #505458;
  }
}
</style>