<template>
  <div>
    <div class="manage-header">
      <el-button type="primary" size="small" @click="() => newProductArea()">
        + 新增栏目</el-button
      >
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="icon" label="产品图片">
        <template slot-scope="scope">
          <img :src="scope.row.icon" min-width="70" height="70" />
        </template>
      </el-table-column>
      <el-table-column prop="name" label="栏目名称"> </el-table-column>
      <el-table-column prop="sort" label="排序"> </el-table-column>
      <el-table-column prop="describe" label="栏目详情"> </el-table-column>
      <!-- <el-table-column prop="status" label="角色状态">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{
                  scope.row.status == 1 ? "启用" : "停用"
                }}</span>
              </template>
            </el-table-column> -->

      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" @click="columnDetail(scope.$index, scope.row)"
            >栏目内容</el-button
          >

          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[30, 50, 100, 200]"
        :page-size="30"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <!-- 表单信息 -->
      <el-form :model="form" ref="form" label-width="100px">
        <el-form-item v-show="showflag" label="id" prop="id">
          <el-input v-model="form.id"></el-input>
        </el-form-item>
        <el-form-item label="栏目名称" prop="name">
          <el-input v-model="form.name" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="产品图片" prop="icon">
          <el-upload
            class="avatar-uploader"
            action="#"
            :http-request="uploadColumn_icon"
            :show-file-list="false"
            accept="image/*"
            :on-success="handleAvatarSuccess"
          >
            <img v-if="form.icon" :src="form.icon" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input v-model="form.sort" placeholder="请输入子排序"></el-input>
        </el-form-item>

        <el-form-item label="栏目封面" prop="banner">
          <el-upload
            class="avatar-uploader"
            action="#"
            :http-request="uploadColumn_banner"
            :show-file-list="false"
            accept="image/*"
            :on-success="handleAvatarSuccess"
          >
            <img v-if="form.banner" :src="form.banner" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="栏目内容" prop="banner">
          <el-upload
            class="avatar-uploader"
            action="#"
            :http-request="uploadColumn_body"
            :show-file-list="false"
            accept="image/*"
            :on-success="handleAvatarSuccess"
          >
            <img v-if="form.body" :src="form.body" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>


        <el-form-item label="栏目描述" prop="describe">
          <!-- <el-input v-model="form.describe" placeholder=""></el-input> -->
          <quill-editor
            ref="myQuillEditor"
            v-model="form.describe"
            class="editor"
            :options="editorOption"
            @focus="quillEditorFocus($event)"
          />
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <!-- <el-button @click="clear">清 除</el-button> -->
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
        
        <script>
import {
  getColumnsImages,
  uploadFile,
  deletFile,
  saveColumnInfo,
  batchDelete,
} from "@/api/column";
import qs from "qs";
export default {
  methods: {
    newProductArea() {
      this.title = "新增栏目信息";
      this.action = "new";
      this.clear();
      this.dialogVisible = true;
    },

    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === 1) {
        return "warning-row";
      } else if (rowIndex === 3) {
        return "success-row";
      }
      return "";
    },

    beforeUpload(file) {
      // 截取上传文件的后缀名
      let fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
      console.log(fileType);
      // 判断文件名的类型，允许多种就判断多个
      if (fileType !== "jpg" && fileType !== "jpeg" && fileType !== "png") {
        this.$message.warning(`请上传jpg、jpge或png的图片！`);
        this.fileList = [];
        return false;
      }
    },
    quillEditorFocus() {},
    // 处理预览操作
    handlePreview(file) {
      console.log(this.fileList[0]);
      console.log(file, "handlePreview");
    },

    uploadColumn_icon(file) {
      uploadFile(file.file).then((res) => {
        if (res.data.code === 200) {
          this.form.icon = res.data.data.filePath;
        }
      });
    },

    uploadColumn_banner(file) {
      uploadFile(file.file).then((res) => {
        if (res.data.code === 200) {
          this.form.banner = res.data.data.filePath;
        }
      });
    },
    uploadColumn_body(file) {
      uploadFile(file.file).then((res) => {
        if (res.data.code === 200) {
          this.form.body = res.data.data.filePath;
        }
      });
    },

    // 处理超出图片个数操作
    handleExceed(files, fileList) {
      this.$message.warning(
        `只能上传一个文件，若需要上传，请先删除之前上传的文件，再上传新的图片`
      );
    },

    // 移除之前的操作
    beforeRemove(file, fileList) {
      if (this.fileList.length === 0) {
        return;
      }
      console.log(fileList[0], "filelist");
      this.$confirm(`确定移除 ${file.name}？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.deleteColumntFile();
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消修改",
          // });
        });

      return false;
    },

    deleteColumntFile() {
      if (
        this.form.icon === "" &&
        this.form.banner === "" &&
        this.form.body === ""
      ) {
        return;
      }
      if (this.form.icon != "") {
        deletFile(this.form.icon).then(({ data }) => {
          if (data.code === 200) {
            this.form.icon = "";
          }
        });
      }

      if (this.form.banner != "") {
        deletFile(this.form.banner).then(({ data }) => {
          if (data.code === 200) {
            this.form.banner = "";
          }
        });
      }
      if (this.form.body != "") {
        deletFile(this.form.body).then(({ data }) => {
          if (data.code === 200) {
            this.form.body = "";
          }
        });
      }
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },

    submit() {
      let msg = "";
      if (this.action === "new") {
        msg = "确认新增栏目?";
      } else {
        msg = "确认修改栏目信息？";
      }
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$confirm(msg, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              saveColumnInfo(qs.stringify(this.form)).then(({ data }) => {
                if (data.code === 200) {
                  this.getColumnInfo();
                  this.$message({
                    type: "success",
                    message: "处理成功!",
                  });
                  this.dialogVisible = false;
                  this.form.id = "";
                  this.form.name = "";
                  this.form.icon = "";
                  this.form.sort = "";
                  this.form.url = "";
                  this.form.body = "";
                  this.form.banner = "";
                  this.form.describe =""
                }
              });
            })
            .catch(() => {});
        } else {
          return false;
        }
      });

      // this.dialogVisible = false;
    },
    handleClose() {
      if (this.action === "new") {
        this.deleteColumntFile();
        this.clear();
      }
      this.dialogVisible = false;
    },
    uploadBannerFile() {},
    clear() {
      let this_ = this;
      this.form.id = "";
      this.form.name = "";
      this.form.icon = "";
      this.form.sort = "";
      this.form.url = "";
      this.form.body = "";
      this.form.banner = "";
      this.form.describe =""
      if (this.action === "edit") {
      } else {
        this.deleteColumntFile();
      }
    },
    cancel() {
      this.handleClose();
    },
    getColumnInfo() {
      var this_ = this;
      getColumnsImages().then((res) => {
        //   console.log(res.data, "getCarouselImages");
        if (res.data.code === 200) {
          this.$nextTick(() => {
            this.tableData = res.data.data.records;
            this.total = res.data.data.total;
          });
          // console.log(this.tableData,"tableData")
        }
      });
    },

    handlePerm() {},
    handleAvatarSuccess() {},
    handleDelete(index, row) {
      const params = { params: { id: row.id } };
      this.$confirm("确认删除该条产品领域信息？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          batchDelete(params).then(({ data }) => {
            if (data.code === 200) {
              this.deleteColumntFile();
              this.getColumnInfo();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          return;
        });
    },
    columnDetail(index, row) {
      this.action = "edit";
      this.title = "编辑栏目信息";
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.form = JSON.parse(JSON.stringify(row));
      });
    },
  },
  mounted() {
    this.getColumnInfo();
  },
  data() {
    return {
      tableData: [],
      showflag: false,
      form: {
        id: "",
        name: "",
        icon: "",
        sort: "",
        url: "",
        body: "",
        banner: "",
        describe:""
      },
      title: "",
      fileList: [],
      action: "",
      dialogVisible: false,
      total: 0,
      size: "",
      current: 0,
      orders: "",
    };
  },
};
</script>
        
        <style lang='less' scoped >
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>