<template>
    <div>
      <div class="manage-header">
        <el-button type="primary" size="small" @click="() => newProjectComming()">
          + 新增</el-button
        >
      </div>
      <el-table max-height="680" :data="tableData" style="width: 100%">
        <el-table-column min-width="15%" prop="year" label="日期">
        </el-table-column>
        <el-table-column min-width="15%" prop="productName" label="产品名称">
        </el-table-column>
  
   
  
        <el-table-column min-width="20%" prop="lingyu" label="产品领域">
        </el-table-column>
      
  
        <el-table-column min-width="20%" label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
  
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="current"
          :page-sizes="[15, 30, 50, 100, 200]"
          :page-size="15"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
  
      <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        width="50%"
        :before-close="handleClose"
      >
        <!-- 表单信息 -->
        <el-form :model="form" ref="form" label-width="100px">
          <el-form-item v-show="showflag" label="id" prop="id">
            <el-input v-model="form.id"></el-input>
          </el-form-item>
  
          <el-form-item label="日期" prop="date">
            <el-date-picker
              v-model="form.date"
              type="year"
              value-format="yyyy-MM"
              placeholder="选择年"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="项目名称" prop="productName">
            <el-input v-model="form.productName" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="sort">
            <el-input v-model="form.sort" placeholder=""></el-input>
          </el-form-item>
  
          <el-form-item label="规格" prop="lingyu">
            <el-input v-model="form.lingyu" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="应用前景" prop="qianjing">
            <el-input v-model="form.qianjing" placeholder=""></el-input>
          </el-form-item>
        </el-form>
  
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel">取 消</el-button>
          <!-- <el-button @click="clear">清 除</el-button> -->
          <el-button type="primary" @click="submit">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </template>
              
              <script>
  import {
    updateProjectCommingSort,
    saveProjectCommingInfo,
    getProjectCommingInfo,
    batchDelete,
  } from "@/api/projectcomming";
  import qs from "qs";
  export default {
    methods: {
      newProjectComming() {
        this.title = "新增即将上市药品";
        this.action = "new";
        this.clear();
        this.dialogVisible = true;
      },
  
      tableRowClassName({ row, rowIndex }) {
        if (rowIndex === 1) {
          return "warning-row";
        } else if (rowIndex === 3) {
          return "success-row";
        }
        return "";
      },
  
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.size = val;
        this.current = 1;
        this.getProjectCommingTableInfo();
      },
      handleCurrentChange(val) {
        this.current = val;
        console.log(`当前页: ${val}`);
        this.getProjectCommingTableInfo();
      },
      handleRemove(file, fileList) {
        console.log(file, fileList);
      },
  
      submit() {
        let msg = "";
        if (this.action === "new") {
          msg = "确认新增即将上市产品?";
        } else {
          msg = "确认修改产品信息？";
        }
        this.$refs["form"].validate((valid) => {
          if (valid) {
            this.$confirm(msg, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
               
                // console.log(qs.stringify(this.form));
                const formData =new FormData();
                formData.append("id",this.form.id)
                formData.append("date",this.form.date)
                formData.append("productName",this.form.productName)
                formData.append("lingyu",this.form.lingyu)
                formData.append("qianjing",this.form.qianjing)
                formData.append("sort",this.form.sort)
                saveProjectCommingInfo(formData).then(
                  ({ data }) => {
                    if (data.code === 200) {
                      this.getProjectCommingTableInfo();
                      this.$message({
                        type: "success",
                        message: "处理成功!",
                      });
                      this.dialogVisible = false;
                      this.form.id = "";
                      this.form.date = "";
                      this.form.productName = "";
                      this.form.lingyu = "";
                      this.form.qianjing = "";
                      this.form.sort = "";
                    }
                  }
                );
              })
              .catch(() => {});
          } else {
            return false;
          }
        });
  
        // this.dialogVisible = false;
      },
      handleClose() {
        if (this.action === "new") {
          this.clear();
        }
        this.dialogVisible = false;
      },
  
      clear() {
        let this_ = this;
        this.form.id = "";
        this.form.date = "";
        this.form.productName = "";
        this.form.lingyu = "";
        this.form.qianjing = "";
        this.form.sort = "";
      },
      cancel() {
        this.handleClose();
      },
      getProjectCommingTableInfo() {
        var this_ = this;
        const page = {
          size: this.size,
          current: this.current,
          //   productAreaId: productAreaId,
        };
        const params = { params: page };
        getProjectCommingInfo(params).then((res) => {
          //   console.log(res.data, "getCarouselImages");
          if (res.data.code === 200) {
            this.$nextTick(() => {
              this.tableData = res.data.data.records;
              this.total = res.data.data.total;
            });
          }
        });
      },
      updateSort(row) {
        console.log(row, "updateSort");
        const params = { params: { id: row.id, sort: row.sort } };
        updateProjectCommingSort(params).then(({ data }) => {
          if (data.code === 200) {
            this.getProjectCommingTableInfo();
            // this.$message({
            //   type: "success",
            //   message: "删除成功!",
            // });
          }
        });
      },
      handlePerm() {},
      handleAvatarSuccess() {},
      handleDelete(index, row) {
        const params = { params: { id: row.id } };
        this.$confirm("确认删除该条产品信息？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            batchDelete(params).then(({ data }) => {
              if (data.code === 200) {
                this.getProjectCommingTableInfo();
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
              }
            });
          })
          .catch(() => {
            return;
          });
      },
      handleEdit(index, row) {
        this.action = "edit";
        this.title = "编辑产品信息";
        this.dialogVisible = true;
        this.$nextTick(() => {
          this.form = JSON.parse(JSON.stringify(row));
        });
      },
    },
    mounted() {
      this.productAreaId = this.$route.query.id;
      this.getProjectCommingTableInfo();
      getProjectCommingInfo().then((res) => {
        if (res.data.code === 200) {
          this.$nextTick(() => {
            this.productArea = res.data.data;
          });
        }
      });
      // this.form.pId = this.$route.query.id;
      this.$set(this.form, "pId", this.$route.query.id);
      // alert(this.form.pId)
    },
    data() {
      return {
        tableData: [],
        showflag: false,
        form: {
          id: "",
          year: "",
          productName: "",
          lingyu: 0,
          qianjing: "",
          sort: "",
          date:"",
        },
        editorOption: {},
        title: "",
        action: "",
        dialogVisible: false,
        total: 0,
        size: 15,
        current: 0,
        orders: "",
        productAreaId: "",
        test: 4,
        productArea: [],
      };
    },
  };
  </script>
              
              <style lang='less' scoped >
  .el-table .warning-row {
    background: oldlace;
  }
  
  .el-table .success-row {
    background: #f0f9eb;
  }
  
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
  }
  .avatar {
    width: 150px;
    height: 150px;
    display: block;
  }
  .ql-editor {
    height: 150px;
  }
  .ql-blank {
    height: 150px;
  }
  .ql-container {
    height: 150px;
  }
  </style>