<!--  -->
<template>
  <div>
    <el-container>
      <el-aside width="auto">
        <common-aside/>
      </el-aside>
      <el-container>
        <el-header><common-header/></el-header>
        <commontag></commontag>
        <el-main>
            <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>


<script>
import CommonHeader from '@/components/CommonHeader.vue';
import Commontag from '../components/Commontag.vue'

import CommonAside from '../components/CommonAside.vue';
import CommontagVue from '@/components/Commontag.vue';
import CommonTag from '../components/Commontag.vue';

export default {
  components: { CommonAside,CommonHeader, Commontag },
   
  //import引入的组件需要注入到对象中才能使用
 
  data() {
    //这里存放数据
    return {};
  }
  
}
</script>
<style lang='scss' scoped>
.el-header{
    padding: 0;
}
//@import url(); 引入公共css类
</style>