import Mock from 'mockjs'
export default {

    getMenu: config => {
        const { userName , password } = JSON.parse(config.body)
        if (userName === 'admin' && password === 'admin') {

            return {
                code: 200,
                data: {
                    menu: [

                        {
                            path: "/home",
                            name: "home",
                            label: "首页",
                            icon: "s-home",
                            url: "Home.vue",
                        },
                        {
                            path: "/mall",
                            name: "mall",
                            label: "商品管理",
                            icon: "video-play",
                            url: "Mall.vue",
                        },
                        {
                            path: "/user",
                            name: "user",
                            label: "用户管理",
                            icon: "user",
                            url: "User.vue",
                        },
                        {
                            label: "其他",
                            icon: "s-tools",
                            children: [
                                {
                                    path: "/page1",
                                    name: "page1",
                                    label: "页面1",
                                    icon: "setting",
                                    url: "PageOne.vue",
                                },
                                {
                                    path: "/page2",
                                    name: "page2",
                                    label: "页面2",
                                    icon: "setting",
                                    url: "PageTwo.vue",
                                },
                            ],
                        },

                    ],
                    token: Mock.Random.guid(),
                    message: "获取成功"
                }
            }
        } else if (userName === 'xiaoxiao' && password === 'xiaoxiao') {

            return {
                code: 200,
                data: {

                    menu: [

                        {
                            path: "/home",
                            name: "home",
                            label: "首页",
                            icon: "s-home",
                            url: "Home.vue",
                        },

                        {
                            label: "其他",
                            icon: "s-tools",
                            children: [
                                {
                                    path: "/page1",
                                    name: "page1",
                                    label: "页面1",
                                    icon: "setting",
                                    url: "PageOne.vue",
                                },
                                {
                                    path: "/page2",
                                    name: "page2",
                                    label: "页面2",
                                    icon: "setting",
                                    url: "PageTwo.vue",
                                },
                            ],
                        },

                    ],
                    token: Mock.Random.guid(),
                    message: "获取成功"
                }
            }
        } else {
            return {
                code: -999,
                data: {
                    message: "密码错误"
                }
            }
        }


    }

}