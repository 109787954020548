<template>
  <div class="content-panel">
    <el-col :span="6">
      <template>
        <div class="content">
          <div>组织列表</div>
          <el-input
            class="searchInput"
            placeholder="输入关键字进行过滤"
            v-model="filterText"
            suffix-icon="el-icon-search"
          >
          </el-input>
          <div class="treeDiv">
            <el-tree
              :data="treeData"
              :props="defaultProps"
              ref="tree"
              highlight-current
              :default-expanded-keys="[1]"
              :default-checked-keys="[1]"
              @node-click="handleNodeClick"
              node-key="id"
              :current-node-key="currentNodeKey"
              :filter-node-method="filterNode"
            ></el-tree>
          </div>
        </div>
      </template>
    </el-col>
    <el-col :span="18">
      <div class="options-panel f-r-b-c">
        <div>
          <el-input
            v-model="params.username"
            placeholder="请输入用户名"
            clearable
            style="margin-right: 30px; width: 150px"
          ></el-input>
          <el-button
            type="primary"
            size="mini"
            style="margin: 34px"
            @click="searchInfoChange"
            >搜索</el-button
          >
          <el-button size="mini" @click="reset">重置</el-button>
        </div>
      </div>
      <div class="buttonDiv">
        <el-button type="primary" @click="createDialog">新增</el-button>
      </div>
      <div class="tableSpan">
        <el-table
          ref="singleTable"
          :data="AccountData"
          style="width: 100%"
          stripe
          fit
          highlight-current-row
          @row-click="handleClickRow"
        >
          <el-table-column
            type="index"
            label="序号"
            width="50"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="orgName"
            label="公司名称"
            width="200"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="username"
            label="用户名"
            width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="roleIds"
            label="权限角色"
            width="150"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="status"
            label="状态"
            width="100"
            align="center"
          >
            <template slot-scope="scope">
              <p v-if="scope.row.status == 1" type="success">启用</p>
              <p v-if="scope.row.status == 0" type="warning">禁用</p>
            </template>
          </el-table-column>
          <el-table-column
            prop="jobDuty"
            label="备注"
            width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="" label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="handleManageRow(scope.row.id)"
                >编辑</el-button
              >
              <el-button @click="handleDeleteRow(scope.row.id)" type="text"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          class="pagelist"
          :current-page.sync="params.pageNum"
          :page-size="params.pageSize"
          layout="total, prev, pager, next"
          :total="params.total"
          @current-change="handlePageChange"
        />
      </div>
    </el-col>
  </div>
</template>

<script>
import { getDDdeptInfo } from "@/api/dept";

export default {
  data() {
    return {
      filterText: "",
      treeData: [],
      defaultProps: {
        children: "children",
        label: "departmentname",
      },
      AccountData:[],
      params: {
        username: "", // 用户名
        orgId: "", // 组织机构id
        status: "", // 状态，0：禁用，1：启用
        pageNum: 1, // 当前第几页
        pageSize: 10, // 每页数量
        total: 0, // 总记录数
      },
      currentNodeKey: "",
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  created() {},
  mounted() {
    getDDdeptInfo().then((res) => {
      this.treeData = res.data.data;
      this.currentNodeKey = res.data.data[0].id;
      this.$nextTick(() => {
        this.$refs.tree.setCurrentKey(res.data.data[0].id);
      });
      this.$emit("getTreeData", res.data.data[0]);
    });
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.departmentname.indexOf(value) !== -1;
    },
    handleNodeClick(data) {
      this.$emit("getTreeData", data);
    },
  },
};
</script>

<style lang='less' scoped>
.searchInput {
  // width: 20%;
}
</style>